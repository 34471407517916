// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";
 
// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: clip;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

[data-type="mainpage"],
[data-type="listing"] {

    main.inner {
        margin-top: 24px;
    }
}


.header-sentinel,
.privacy-sentinel {
    height: 0;
    clear: both;
}

.top-bar {
    display: flex;
    align-items: center;
    position: relative;
    gap: 24px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.08em;
    padding-block: 14px;

    &:after {
        .full-bg();
        background: @light-bg;
    }

    a, span {
        color: @header-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-contact {
        display: flex;
        gap: inherit;

        li {
            @media(min-width: 1280px) {
                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    li,
    > span {
        display: flex;
        align-items: center;
        color: @header-color;
        gap: 10px;

        &:before {
            .small-icon();
            font-size: 20px;
            content: '';
        }
    }

    .hours:before {
        content: "\e91b";
    }

    li:has(.phone):before {
        content: "\e91a";
    }

    li:has(.email):before {
        content: "\e919";
    }

    .topbar {
        margin-left: auto;
        display: flex;
        gap: 24px;

        li {
            gap: 0;
        }

        a {
            text-transform: uppercase;
            font-size: 12px;

            &:hover {
                color: @main-color;
            }
        }
    }
}

// SHOP-HEADER
@media (min-width: 1280px) {
    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        transition: box-shadow .2s ease-in-out, transform .2s ease-in-out;
        z-index: 1000;

        &.stuck {
            .shadow();
            transform: translateY(-40px);

            .shop-header-container {
                transform: translateY(20px);
            }

            .mainmenu:after,
            .mainmenu a:after {
                opacity: 0;
            }
        }
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    padding-block: 32px;
    transition: transform 0.2s ease-in-out;
    position: relative;
    z-index: 104;
}

// LOGO
.logo {
    height: 72px;
    aspect-ratio: ~"263/72";
    transform-origin: left top;
    transition: transform .2s ease-in-out;

    img {
        .img-contain();
    }

}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: inherit;
    margin-right: 8px;
}

.compare-box,
#cart-box,
#wishlist-box,
.log-button,
.search-engine-trigger,
.tree-trigger {
    display: block;
    cursor: pointer;
    position: relative;
    padding-top: 30px;

    &:before {
        font-family: icomoon;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        line-height: 1;
        color: @header-color;
    }

    em {
        color: @header-color;
        font-style: normal;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.08em;
        display: block;
    }

    @media (min-width: 1280px) {
        &:hover:before {
            color: @main-color;
        }
    }
}

.compare-box {

    &:before {
        content: "\e917";
    }

    &:not(.has-two-products) {
        pointer-events: none;

        em, &:before {
            color: darken(@footer-gray, 10%);
        }
    }
}

#wishlist-box {

    &:before {
        content: "\e916";
    }

    &[data-wishlist-count="0"]:after {
        display: none;
    }
}

.log-button {

    &:before {
        content: "\e915";
    }
}

.search-engine-trigger {

    &:before {
        content: "\e918";
    }
}

#cart-box {

    &:before {
        content: "\e914";
    }

}

#wishlist-box:after,
#cart-box i {
    content: '';
    font-size: 0;
    position: absolute;
    bottom: 11px;
    right: -8px;
    width: 6px;
    height: 6px;
    background-color: #2AF70A;
    border-radius: 50%;
}

@media(min-width: 1280px) {
    .tree-trigger,
    .tree-canvas,
    .search-engine-trigger {
        display: none;
    }
}

// SEARCH ENGINE
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 508px;
        z-index: 100;
        transition: transform .2s ease-in-out;

        &.show,
        &.show-last-phrases {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 64px 0 20px;
            background-color: white;
            border-radius: 0;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;
            border: 1px solid @border-color;

            &::-webkit-input-placeholder {
                color: @header-color;
            }
            &::-moz-placeholder {
                color: @header-color;
            }
            &:-ms-input-placeholder {
                color: @header-color;
            }
            &:-moz-placeholder {
                color: @header-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 56px;
            height: 48px;
            color: @header-color;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: "\e918";
                font-family: icomoon;
            }

            &:hover {
                color: @main-color;
            }
        }
    }
}

#suggestions {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

// MAIN BAR
@media (min-width: 1280px) {

    #main-bar {
        position: relative;
        display: flex;
        gap: 32px;
        z-index: 103;
        justify-content: space-between;
        min-height: 56px;
    }

    .mainmenu {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 18px;
        justify-content: space-evenly;
        border-top: 1px solid @border-color;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: @border-color;
            bottom: 0;
            left: 0;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            z-index: -1;
        }

        li a {
            padding: 17px 0; // controls main bar height
            position: relative;
            font-size: 14px;
            letter-spacing: 0.08em;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: color 0.1s ease-in-out;
            color: @header-color;

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 0px;
                height: 1px;
                opacity: 0;
                transition: all 0.2s ease-in-out;
                background: @main-color;
                left: 50%;
                transform: translateX(-50%);
            }
        }

            li a:hover,
            li.selected a {
                color: @main-color;

                &:before,
                &:after {
                    width: 100%;
                    opacity: 1;
                }
            }
    }

    // TREE
    .tree-top-hover {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 320px;
        background: @main-color;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 700;
        color: white;
        padding: 8px 20px;

        .tree-top-hamburger {
            .grid();
            grid-template-columns: repeat(2, minmax(0, 1fr));
            margin-right: 12px;
            gap: 2px;

            i {
                width: 8px;
                height: 8px;
                gap: 2px;
                border: 1px solid white;
            }
        }

        &:after {
            .small-icon();
            content: "\e91c";
            font-size: 16px;
            color: white !important;
            margin-left: auto;
        }
    }

    .menu-wrapper {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease-in-out;
        transition-delay: 0.2s;
        top: calc(100% + 1px);
        left: 0;
        transform: translateY(-10px); // for animation
        padding: 6px 6px 6px 0;
        background: @main-color;

        li > .menu-wrapper {
            top: 0;
            left: calc(100% + 1px);
            transform: translateX(-10px); // for animation
        }
    }

    #tree-top:hover > .menu-wrapper,
    li:hover > .menu-wrapper {
        opacity: 1;
        visibility: visible;
        transform: none !important;
    }


    #tree-top,
    #tree-top li { // both nested lists

        small {
            display: none;
        }

        a {
            display: block;
            font-weight: 700;
            color: white;
            padding: 8px 42px 8px 20px;
            position: relative;
        }

        ul {
            padding: 16px 0;
            background: @main-color;
            .shadow();
            max-height: 505px;
            overflow-y: auto;
            .scrollbar();

            &:before,
            &:after {
                content: '';
                position: absolute;
                height: 24px;
                width: calc(100% - 15px);
                z-index: 1;
                left: 0;
            }

            &:before {
                top: 0;
                background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(8,87,124,1) 100%);
            }

            &:after {
                bottom: 0;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(8,87,124,1) 100%);
            }
        }

        .parent > a:after {
            .small-icon();
            content: "\e91c";
            font-size: 16px;
            color: white !important;
            transition: transform 0.2s;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%) rotate(-90deg);
        }

        &:hover {

            > a {
                color: #9CBCCB !important;
            }
        }
    }

    #tree-top { // first nested list
        position: relative;

        > ul {
            width: auto;

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 8px;
                width: 100%;
                left: 0;
                top: -4px;
            }
        }

        &:hover {

            .tree-top-hover {
                color: #9CBCCB !important;

                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }

    #tree-top li { // side nested lists

        .menu-wrapper {

            &:before { // redundancy for hover
                position: absolute;
                content: '';
                background: transparent;
                display: block;
                height: 100%;
                width: 8px;
                top: 0;
                left: -4px;
            }
        }

        ul {
            min-width: 320px;
            width: auto;
            height: 505px;
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGap);
    width: 100%;

    &:not(:has(.product)) {
        display: none;
    }
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 35px;

    .middle-info {
        display: contents;
    }

    i {
        display: none;
    }
}

.product-image {
    aspect-ratio: ~"277/185";
    position: relative;
    transition: transform .15s ease-in-out;
}

a.product-name {
    display: block;
    font-weight: 600;
    text-align: center;
    color: @header-color;
    z-index: 1; // IMPORTANT
    word-break: break-word;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    border: 1px solid @main-color;
    padding: 8px 16px;
    .grid();
    grid-template-columns: minmax(0,1fr) auto;
    gap: 4px;
    z-index: 1;

    .brutto-price {
        grid-column: span 2;
    }
}

.product-price {
    display: contents;

    .brutto-price {
        display: flex;
        align-items: flex-end;
        gap: 4px 12px;
        flex-wrap: wrap;
    }

    b, del, .netto-price {
        line-height: 1.2;
        font-size: 16px;
        white-space: nowrap;

        &:after {
            content: ' zł';
        }
    }

    b {
        color: @main-color;
    }

    del {
        font-size: 14px;
        color: rgba(36,22,35, 0.5);
        position: relative;
        top: -1px;
    }

    .brutto-price b,
    .netto-price {

        &:before {
            line-height: 1.5;
            font-size: 12px;
            display: block;
            position: relative;
            top: 1px;
            color: @header-color;
            font-weight: normal;
        }
    }

        .brutto-price b:before {
            content: 'Brutto:';
        }

        .netto-price:before {
            content: 'Netto:';
        }
}

.product-add {
    cursor: pointer;
    display: block;
    background-color: @main-color;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 16px;

    &:before {
        .small-icon();
        content: "\e914";
        font-size: 20px;
    }

    &:hover {
        background: #307291;
    }

    &.disabled {
        background: @footer-gray;
    }
}

.promo-badge {
    position: absolute;
    top: 0;
    right: 0;
    background: @error-color;
    padding: 4px 8px;
    color: white;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.2;
}

.product-hover {
    position: absolute;
    width: calc(100% + 2px);
    aspect-ratio: ~"277/185";
    top: -1px;
    left: -1px;
    background-color: rgba(255,255,255, 0.9);
    opacity: 0;
    transition: all .15s ease-in-out;
    padding-top: 25px;

    > p {
        transform: translateY(-8px);
        transition: transform .15s ease-in-out;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        font-size: 14px;
        --fontSize: 14px;
        max-height: ~"calc(4 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1280px) {
    .product:hover {

        .product-hover {
            opacity: 1;
            transition-delay: 0.15s;

            > p {
                transition-delay: .15s;
                transform: translateY(0);
            }
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding-block: 40px 32px;
    margin-bottom: ~"calc(var(--rowGap) * -1)";
    font-size: 14px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 5px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

.inner.aside {
    grid-template-rows: auto 1fr;
    
    .sidebar {
        grid-column: 1 / 2;
        grid-row: span 2;
    }

    #breadcrumb {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }

    #content {
        grid-column: 2 / 3;
    }
}
 
// BANNER FLOATING TEXT
.banner-text-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    color: white;
    background: rgba(36,22,35, 0.9);
    text-align: center;
    right: 0;
    left: 0;
    width: fit-content;
    margin: 0 auto;

    b {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
    }
}

// COMPARISON PAGE
.comparison-products {
    width: 100%;
    float: left;
    clear: both;

}

.comparison-header {
    display: flex;
    gap: var(--prodGapHor);

    > div {
        flex: 1 1 0;
    }

    &:has(.comparison-product:nth-of-type(3)) {

        .comparison-product .confirm-button {
            padding-inline: 24px;
        }
    }
}

.infobox.comparison-empty {

    .header-wrapper {
        width: 100%;
        height: 248px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &:not(:only-child) {
        justify-content: center;
        border: none;
        border-right: 1px solid @border-color;
        padding: 0;

        h3 {
            font-size: 24px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            padding-inline: 16px;
        }

        a {
            height: 38px;
        }

        > * {
            width: auto;
        }

        &:before,
        .infobox-message {
            display: none;
        }
    }
}

.comparison-product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr auto;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.comparison-product-name {
    font-weight: 600;
    text-align: center;

    &:first-letter {
        text-transform: uppercase;
    }
}

.comparison-product-image {
    position: relative;
    width: 100%;
    max-width: 277px;
    aspect-ratio: ~"277/185";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }
}

.comparison-info-bottom {
    padding: 8px 16px;
    border: 1px solid @main-color;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .bruttoprice,
    .nettoprice {
        line-height: 1.2;
        white-space: nowrap;
        text-align: center;

        &:before {
            display: block;
            font-size: 12px;
            line-height: 1.5;
            color: @font-color;
            font-weight: normal;
        }

        &:after {
            content: " zł";
        }

        &:empty {
            display: none;
        }
    }

    .bruttoprice {
        color: @main-color;
        font-size: 16px;
        flex-wrap: wrap;

        &:before {
            content: 'Brutto:';
        }
    }

    .nettoprice {
        font-size: 16px;

        &:before {
            content: 'Netto:';
        }
    }

    a {
        z-index: 1;
        height: 30px;
        margin-top: auto;
        width: 100%;
    }
}


.comparison-table {
    margin-top: 30px;
    padding-bottom: 30px;

    &:empty {
        display: none;
    }

    .row {
        display: flex;
        border-bottom: 1px solid @border-color;

        &:first-child {
            border-top: 1px solid @border-color;
        }

        > div {
            flex: 1 1 0;
            margin-right: 30px;
            display: flex;
            align-items: flex-start;
            padding: 10px 0;
            font-size: 13px;

            &:last-child {
                margin-right: 0;
            }

            &:nth-child(1) {
                font-weight: bold;
                align-items: flex-start;
                justify-content: flex-end;
                text-align: right;
            }
        }
    }
}